import { useEffect, useReducer } from 'react'

import { useQuery } from 'react-query'
import _camelCase from 'lodash/camelCase'
import _lowerCase from 'lodash/lowerCase'
import { expandState } from '@helpers/geo-location'

const byLocationName = ([a], [b]) => {
  if (a === 'Puerto Rico') {
    return 1
  }
  return a.localeCompare(b)
}

const byStoreType = (a, b) => {
  if (
    a.storeType === 'Showroom' ||
    a.storeTypeId === 'A' ||
    ((a.storeType === 'Kids' || a.storeTypeId === 'K') && b.storeType !== 'Showroom' && b.storeTypeId !== 'A') ||
    ((a.storeType === 'Patio' || a.storeTypeId === 'P') && (b.storeType === 'Outlet' || b.storeTypeId === 'C'))
  ) {
    return -1
  }
  return 1
}

export const formatStoresAttributes = store =>
  Object.entries(store).reduce((formattedStore, property) => {
    if (_lowerCase(property[0]) === 'zip') {
      return {
        ...formattedStore,
        zipcode: property[1],
      }
    }
    return {
      ...formattedStore,
      [_camelCase(property[0])]: property[1],
    }
  })

export const sortStores = (stores, shouldFormatStores = false) => {
  const result = stores?.reduce?.((collection, storeContext) => {
    const store =
      typeof storeContext.node === 'object' && shouldFormatStores
        ? formatStoresAttributes(storeContext.node)
        : storeContext

    const market = store.market || ''
    const stateName = expandState(store.state)
    const label = `${store.city}${store.storeName ? ` - ${store.storeName}` : ''} ${store.storeType}`
    const cityName = `${store.city}, ${store.state}`
    const currentState = collection[stateName] || {}
    const currentMatket = currentState[market] || {}
    const currentCities = currentMatket[cityName] || []
    const currentStore = { ...store, label, stateName }

    return {
      ...collection,
      [stateName]: {
        ...currentState,
        [market]: {
          ...currentMatket,
          [cityName]: [...currentCities, currentStore],
        },
      },
    }
  }, {})

  if (!result) return []

  return Object.entries(result)
    .sort(byLocationName)
    .map(([state, market]) => [
      state,
      Object.entries(market)
        .sort(byLocationName)
        .map(([marketName, cities]) => [
          marketName,
          Object.entries(cities)
            .sort(byLocationName)
            .map(([cityName, storesArr]) => [cityName, storesArr.sort(byStoreType)]),
        ]),
    ])
}

const fetchStoresData = async () => {
  const url = `${process.env.GATSBY_GET_STORE_INFO_URL}/v1/stores`
  return fetch(url)
    .then(response => response.json())
    .then(sortStores)
}

export const useSortedStores = () => useQuery('all-stores', fetchStoresData)

const filterActions = {
  showroom: 'SHOWROOMS_TOGGLE',
  kids: 'KIDS_TOGGLE',
  patio: 'PATIO_TOGGLE',
  outlet: 'OUTLET_TOGGLE',
  setUrlFilters: 'SET_URL_FILTERS',
}

const filterReducer = (state, action) => {
  switch (action.type) {
    case filterActions.showroom:
      return { ...state, showroom: !state.showroom }

    case filterActions.kids:
      return { ...state, kids: !state.kids }

    case filterActions.patio:
      return { ...state, patio: !state.patio }

    case filterActions.outlet:
      return { ...state, outlet: !state.outlet }

    case filterActions.setUrlFilters:
      return { ...action.payload }

    default:
      throw new Error('INVALID ACTION')
  }
}

/**
 * @typedef {Object} StoreFilterState
 * @property {boolean} showroom is showroom filter active
 * @property {boolean} kids is kids filter active
 * @property {boolean} patio is patio filter active
 * @property {boolean} outlet is outlet filter active
 */

/**
 * @typedef {Object} StoreFilterActions
 * @property {() => void} showroom toggles showroom filter
 * @property {() => void} kids toggles kids filter
 * @property {() => void} patio toggles patio filter
 * @property {() => void} outlet toggles outlet filter
 * @property {(object) => void} setUrlFilters sets initial state from url
 */

/**
 * @typedef {Object} StoreFilter
 * @property {StoreFilterState} state
 * @property {StoreFilterActions} actions toggles kids filter and sets state
 */

/**
 * Store filters react hook
 * @returns {{ state: StoreFilterState, actions: StoreFilterActions }} state and actions objects
 */

export const useStoreFilters = () => {
  const [state, dispatch] = useReducer(filterReducer, { showroom: true, kids: true, patio: true, outlet: true })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const searchParams = urlSearchParams.get('filters')?.split(',')
      if (searchParams?.[0]) {
        // Checks if state is included in search params and generates new state
        const urlFilters = Object.keys(state).reduce((acc, filter) => {
          acc[filter] = searchParams.includes(filter)
          return acc
        }, {})
        dispatch({ type: 'SET_URL_FILTERS', payload: urlFilters })
      }
    }
    // eslint-disable-next-line
  }, [])

  return {
    state,
    actions: {
      showroom: () => dispatch({ type: filterActions.showroom }),
      kids: () => dispatch({ type: filterActions.kids }),
      patio: () => dispatch({ type: filterActions.patio }),
      outlet: () => dispatch({ type: filterActions.outlet }),
    },
  }
}
