import React from 'react'
import { getStandardTime, weekdays } from '@helpers/string-helper'
import { isArray, isEmpty } from 'lodash'

export const getSpecialClosings = ({ data, mark }) => {
  let SpecialClosings = null
  if (data && data.allStrapiStore && data.allStrapiStore.edges && data.allStrapiStore.edges.length) {
    data.allStrapiStore.edges.forEach(({ node }) => {
      if (
        node &&
        node.StoreNumber &&
        mark &&
        mark.StoreNumber &&
        mark.StoreNumber.toString() === node.StoreNumber.toString()
      ) {
        SpecialClosings = node.SpecialClosings
      }
    })
  }
  return SpecialClosings
}

export const showDay = (hour, index, currentDay, tomorrowDay) => {
  let daytxt = false
  const day = hour.day ? hour.day : weekdays[hour.dayIndex]
  if (day === currentDay) daytxt = 'Today'
  if (day === tomorrowDay) daytxt = 'Tomorrow'

  return (
    daytxt && (
      <div className="hour" key={index}>
        <span className="day">{daytxt}</span>:{' '}
        {hour.openTime && hour.closeTime
          ? `${getStandardTime(hour.openTime)} - ${getStandardTime(hour.closeTime)}`
          : 'Closed'}
      </div>
    )
  )
}

export const getAcceptingSAS = ({ data, mark }) => {
  const edges = data?.allStrapiStore?.edges
  if (isArray(edges) && !isEmpty(edges)) {
    return !edges.some(
      ({ node }) =>
        node?.InStoreAppointments === false && node?.StoreNumber?.toString() === mark?.storeNumber?.toString(),
    )
  }
  return true
}

export const titleCase = str => {
  if (['ne', 'nw', 'se', 'sw'].includes(str.substring(str.length - 2))) {
    return str
      .toLowerCase()
      .replace(/\b\w/g, s => s.toUpperCase())
      .replace(/\s\b\w\w$/g, abbrev => abbrev.toUpperCase())
      .replace(' us ', ' US ')
      .replace(' Us ', ' US ')
      .replace(' Ne ', ' NE ')
      .replace(' Nw ', ' NW ')
      .replace(' Se ', ' SE ')
      .replace(' Sw ', ' SW ')
      .replace(/\.$/g, ' ')
  }
  return str
    .toLowerCase()
    .replace(/\b\w/g, s => s.toUpperCase())
    .replace(' us ', ' US ')
    .replace(' Us ', ' US ')
    .replace(' Ne ', ' NE ')
    .replace(' Nw ', ' NW ')
    .replace(' Se ', ' SE ')
    .replace(' Sw ', ' SW ')
    .replace(/\.$/g, ' ')
}
